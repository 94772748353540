html,
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  overflow-x: hidden;
  overflow-y: visible;
  background-color: "#0065c2";
}

html {
  overflow-y: scroll;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.shake {
  animation: shakeAnimation 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shakeAnimation {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Bahnschrift";
  src: local("Bahnschrift"), url(./fonts/bahnschrift.ttf) format("truetype");
}

@font-face {
  font-family: "Figtree";
  src: local("Figtree"), url(./fonts/figtree.ttf) format("truetype");
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Bahnschrift;
  font-weight: lighter;
}

p {
  font-family: Figtree;
}

span {
  font-family: Figtree;
}

div {
  font-family: Figtree;
}

h4 {
  font-size: 17px;
}

::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: #2a993f;
}
